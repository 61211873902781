import React, { useState, useEffect, useRef } from "react";
import { MdMyLocation } from "react-icons/md";
import { fromLatLng } from "react-geocode";

function MapSearch({
  short = false,
  displayLocationIcon = true,
  searchedLocation = "",
}) {
  const [input, setInput] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    // Load the Google Maps Places library
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB4H6Vcc92d4HnLBTdY1rKaPCFlt-LQ1k4&libraries=places`;
    script.async = true;
    script.onload = () => {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    setInput(localStorage.getItem("address"));
  }, []);

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setInput(inputText);

    console.log("abc ", {inputText})

    if (inputText && autocompleteService.current) {
      console.log("abc123 ", {inputText}, autocompleteService.current)
      // Get location predictions from Google Places API
      autocompleteService.current.getPlacePredictions(
        { input: inputText },
        (predictions, status) => {
          console.log("abc456 ", {inputText})
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions || []);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(
      short
        ? suggestion.structured_formatting.main_text
        : suggestion.description
    );
    setSuggestions([]);

    // Fetch place details to get coordinates
    placesService.current.getDetails(
      { placeId: suggestion.place_id },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const location = place.geometry.location;
          setCoordinates({ lat: location.lat(), lng: location.lng() });
          localStorage.setItem("mapLatitude", location.lat());
          localStorage.setItem("mapLongitude", location.lng());
          localStorage.setItem("address", place.formatted_address);
          window.location.reload();
        }
      }
    );
  };

  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setMarker({lat: latitude, lng: longitude});
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        console.log("abc ", { latitude }, { longitude })
        const lat = latitude;
        const lng = longitude;
        fromLatLng(lat, lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            localStorage.setItem("address", address)
            console.log("abc ", { address })
            const countryComponent = addressArray.find((component) =>
              component.types.includes("country")
            );
            localStorage.setItem("userCountryCode", countryComponent.short_name);
            console.log("abc ", { countryComponent })

            // window.location.reload();
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );

  }

  return (
    <div className="container">
      <div
        style={{
          position: "relative",
          border: "1px solid black",
          borderRadius: short ? "0px" : "30px",
          backgroundColor: "white",
          display: "flex",
          padding: "7px",
          marginLeft: "-10px",
        }}
      >
        <img
          src="/images/pin-point.png"
          alt="pin-point"
          title="Search Location"
          style={{ height: "25px", marginTop: '1%' }} />
        <input
          className="search-container"
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder={short ? "" : "Where would you like us to serve you?"}
          style={{ padding: "8px", fontSize: '16px' }}
        />
        {displayLocationIcon && (
          <button
            style={{
              width: short ? "10%" : "50%",
              backgroundColor: "#9e6dc8",
              border: "none",
              outline: "none",
              borderRadius: "30px",
              marginLeft: "2%",
            }}
            onClick={setCurrentLocation}
          >
            <MdMyLocation /> {short ? "" : "Set My Location"}
          </button>
        )}
        {suggestions.length > 0 && (
          <ul
            style={{
              position: "absolute",
              top: "100%",
              left: "0",
              right: "0",
              backgroundColor: "#fff",
              listStyle: "none",
              padding: "0",
              margin: "0",
              border: "1px solid #ddd",
              maxHeight: "200px",
              overflowY: "auto",
              textAlign: "left",
            }}
          >
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default MapSearch;
