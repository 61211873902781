import React, { useState, useEffect, useRef } from "react";
import {useSelector} from "react-redux";
import { Link } from "react-router-dom";

export const ScrollableCategories = ({ categories }) => {
  // State to track the active category in view
  const [activeCategory, setActiveCategory] = useState();

  // Refs to each category section
  const sectionRefs = useRef([]);
  const currentLanguage = localStorage.getItem("currentLanguage")
  ? localStorage.getItem("currentLanguage")
  : "en";

  useEffect(() => {
    // Observer to detect which category section is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCategory(entry.target.id);
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px", threshold: 0.1 }
    );

    // Observe each section
    sectionRefs.current.forEach((section) => observer.observe(section));

    // Clean up observer on component unmount
    return () => observer.disconnect();
  }, []);

  const scrollToCategory = (categoryId) => {
    const categoryElement = sectionRefs.current.find(
      (section) => section.id === categoryId
    );
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="container">
      <div style={{ display: "flex" }}>
        {/* Left Sidebar with Category Names */}
        <div
          style={{
            width: "55%",
            position: "sticky",
            top: "0",
          }}
        >
          <p style={{ fontSize: '24px' }}>All Categories</p>
          {categories?.map((category) => (
            <div
              key={category.id}
              onClick={() => scrollToCategory(category.id)}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor:
                  activeCategory === category.id ? "#d1e0ff" : "transparent",
                borderRadius: "5px",
                marginBottom: "5px",
                fontSize: '14px'
              }}
            >
              {currentLanguage === "en" ? category.name : category.name_arabic}
            </div>
          ))}
        </div>

        {/* Right Side Service List */}
        <div
          style={{
            flexGrow: 1,
            overflowY: "scroll",
            height: "150vh",
            scrollbarWidth: "none", 
          }}
        >
          {categories?.map((category, index) => (
            <div
              key={category.id}
              id={category.id}
              ref={(el) => (sectionRefs.current[index] = el)}
              style={{ marginBottom: "20px" }}
            >
              <h3>{category.name}</h3>
              <ul>
                <div
                  className="services-grid"
                >
                  {category.serviceTypes?.slice(0, 3).map((service, idx) => (
                  <Link
                    className="services-card"
                    to={`/new-service/${service.id}`}
                  >
                    <img
                      src={service.icon}
                      alt={service.name}
                      title={service.name}
                      className="card-img"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{currentLanguage === "en" ? service.name : service.name_arabic}</h5>
                    </div>
                  </Link>
                  ))}

                  {/* Render placeholders for remaining columns */}
                  {Array.from({ length: 3 - category.serviceTypes?.slice(0, 3)}).map((_, index) => (
                    <div
                      key={`placeholder-${index}`}
                      style={{
                        visibility: "hidden", // Keeps space for empty columns
                      }}
                    ></div>
                  ))}

                </div>
                {/* Display remaining services side by side as plain text */}
                <div className="remaining-services-container">
                  {category.serviceTypes?.slice(3).map((service) => (
                    <Link
                      className="plain-text-service"
                      to={`/new-service/${service.id}`}
                    >
                      {currentLanguage === "en" ? service.name : service.name_arabic}
                    </Link>
                  ))}
                </div>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollableCategories;
