import Header from "./Header";
import {useTranslation} from "react-i18next";
import ScreenedProfessionals from "./ScreenedProfessionals";
import HappynessGuarantee from "./HappynessGuarantee";
import AppDownload from "./AppDownload";
import ManyReason from "./ManyReason";
import Testimonilas from "./Testimonilas";
import HomeServices from "./HomeServices";
import ServicesCard from "./ServicesCard"
import RatedPros from "./RatedPro";
import { useEffect, useState } from "react";
import HomeAllServices from "../pages/all_services/HomeAllServices";
import Footer from "./Footer";

const HomeNew = () => {
  const {t} = useTranslation();
  const [searchedLocation, setSearchedLocation] = useState("")

  useEffect(()=>{
    setSearchedLocation(localStorage.getItem("address"))
  }, [])
  return (
    <>
    <Header />
      {searchedLocation ? (<HomeAllServices />) : (
        <>
      <HomeServices />
    <ServicesCard />
        </>
        
      )}
   
    <ScreenedProfessionals />
    <HappynessGuarantee />
    <AppDownload />
    {/* <div>
      <h3>Are you a top-rated pro?</h3>
      <p>RightHand is always on the lookout for talented service professionals, from cleaners and handymen to smart home installers, who are committed to delivering outstanding customer service.
         The best in the business rely on Right Hand for job opportunities with no lead fees and flexible scheduling.</p>
         <button> Become a Pro</button>
    </div> */}
    <RatedPros />
    <ManyReason />
    <Testimonilas />
    <Footer />
    </>
  );
}

export default HomeNew;
