import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getHomeMainService } from "../actions/homeAction";
import { Link } from "react-router-dom";
import Loader from "./Loader";

const ServicesCard = () => {
    const { t } = useTranslation();
    const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const dispatch = useDispatch();
    const getAllServicesState = useSelector((state) => state.homeReducer);
    const { loading, servicesResult, success } = getAllServicesState;
    const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

    useEffect(() => {
        const countryID = localStorage.getItem("userCountryId");
        if (countryID) {
            const listMainUrl = `${baseUrl}/home/dashboard?country_id=${countryID}`;
            dispatch(getHomeMainService(listMainUrl));
        }
    }, [dispatch]);

    const servicesFirstFour = servicesResult?.slice(0, 4) || [];

    return (
        <div className="container">
            <div className="grid-container">
                <div className="grid-x grid-margin-x">
                    <div className="cell shrink">
                        <h2>{t("you_name_it_we_do_it")}</h2>
                    </div>
                </div>
                <div className="grid-x grid-margin-x">
                    <div className="cell shrink show-for-medium d-flex justify-content-between">
                        <div className="description">
                            Quickly secure top-rated experts for cleaning and handyman services at a transparent, fixed price.
                        </div>
                        <Link className="mixpanel card-link" to={`/services`} style={{ color: "#00CDED", fontSize: '1rem', fontWeight: 'bold'}}>{"See All >"}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="grid-container">
                <div className="grid-x grid-margin-x">
                    <div className="cell small-6 medium-3">
                        <div className="popular-card">
                            {loading ? (
                                <Loader/>
                            ) : (
                                servicesFirstFour.map((service, i) => (
                                    <Link key={service.id} className="mixpanel card-link" to={`/new-service/${service.id}`}>

                                            <img className="service-image" src={service.icon} alt={service.name} title={service.name}/>
                                        
                                        <h3 className="service-name">
                                            {currentLanguage === "en" ? service.name : service.name_arabic} {">"}
                                        </h3>
                                        <br />
                                    </Link>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesCard;
