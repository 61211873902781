import { Link } from "react-router-dom"

const RatedPros = () => {
    return (
        <div className="container">
            <div className="become-pro">
                <div className="grid-container">
                    <div className="grid-x align-middle">
                        <div className="cell small-12 medium-5 become-pro-container">
                            <div className="cell become-pro-title">Are you a top-rated pro?</div>
                            <div className="cell become-pro-para">RightHand is always on the lookout for talented service professionals, from cleaners and handymen to smart home installers, who are committed to delivering outstanding customer service.
                               The best in the business rely on Right Hand for job opportunities with no lead fees and flexible scheduling.
                            </div>
                            <div className="cell">
                                <Link to="https://righthands.applytojob.com/apply" className="pro-button color-rh mixpanel">
                                    Become a pro
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src="/images/pro.jpg" alt="loading pro images" title="professionals" style={{ height: '720px', width: '480px'}} className="hide-small-screen"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatedPros