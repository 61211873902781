import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ScreenedProfessionals = () => {
  const { t } = useTranslation();

  return (
    <>
    <div className='pt-7 pb-4 screenProfessional'>
      <div className="heading">
        <h2><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/lock.webp"  className='me-2' />{t('vetted_screened_professionals')}</h2>
        <p>{t('cleaning_and_handyman_tasks_booked')} <br/><Link to={""}>{t('learn_more')}</Link></p>
      </div>
    </div>
    </>
  )
}

export default ScreenedProfessionals;