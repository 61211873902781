import React from "react";
import NavMenu from "./NavMenu";
import NavUser from "../components/NavUser";
function Header() {
  const isLoggedIn = (localStorage.getItem('currentToken') && localStorage.getItem('currentToken') !== "null") ? true : false;
  return (
    <div>
      <NavUser isLoggedIn={isLoggedIn} />
    </div>
  );
}

export default Header;
