import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HappynessGuarantee = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const handelBook = (step) => {
    navigate(`/frequency/checkout/details?step=${step}`, {
      state: {
        serviceId: "63ac38318a89315fd0fc4f6d",
        isBookNow: true,
        isLogin: currentUser ? true : false,
        ui_type: "Frequency"
      }
    });
  };

  return (
    <>
      <div className="happynesssArea">
        <div className="container" style={{ marginTop: '0px' }}>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="happyContent">
                <h2 className="mb-4">{t("your_happiness_guaranteed")}</h2>
                <p>{t("your_happiness_is_our_goal")}</p>
                {currentUser ? (
                  <button
                    onClick={() => handelBook(1)}
                    className="btn btn-secondary pull-right"
                  >
                    {t("Book_with_Happiness_Guarantee")}
                  </button>
                ) : (
                  <button
                    onClick={() => handelBook(2)}
                    className="btn btn-secondary pull-right"
                  >
                    {t("Book_with_Happiness_Guarantee")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HappynessGuarantee;
