import React from 'react'
import { useTranslation } from 'react-i18next';

const AppDownload = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="rightHandsApp">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h5>{t('take_us_everywhere')}</h5>
                <h2>{t('get_the_right_hands')}</h2>
                <p>
                  {t('book_and_manage')}
                </p>
              </div>
              <div className=" burttonImage mt-5">
                <a href={'https://play.google.com/store/apps/details?id=com.righthands'} style={{display: 'inline-block'}}>
                  <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" title="Get it on Google Play" style={{width: '246px', height: '82px', verticalAlign: 'middle', objectFit: 'contain'}} />
                </a>
                <a href="https://apps.apple.com/ae/app/right-hands/id1641289996?itscg=30200&itsct=apps_box_badge&mttnsubad=1641289996" style={{display: 'inline-block'}}>
                  <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1703030400" alt="Download on the App Store" title="Download on the App Store" style={{width: '192px', height: '64px', verticalAlign: 'middle', objectFit: 'contain'}} />
                </a>
              </div>
            </div>
            <div className="col-lg-6 appImage ">
              <img alt="" title="RightHands" src="/images/phone-app.jpg" width="480" className="hide-small-screen"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppDownload
