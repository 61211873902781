import React from "react";
import { useTranslation } from 'react-i18next';

function ManyReason() {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="manyReasonsec w-full text-white">
        <div className="container">
          <div className="heading"><br/><br/>
            <h2>{ t('so_many_reason') }</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('trusted_cleaners') }</h3>
                  <p>
                    { t('trusted_cleaners_desc') }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('no_hidden_cost') }</h3>
                  <p>
                    { t('no_hidden_cost_desc') }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('same_day_availability') }</h3>
                  <p>
                    { t('same_day_availability_desc') }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('superior_customer_service') }</h3>
                  <p>
                    { t('superior_customer_service_desc') }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('instant_online_booking') }</h3>
                  <p>
                    { t('instant_online_booking_desc') }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <div className="checkicon checkicon-white me-4">
                  <i>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/check.webp" />
                  </i>
                </div>
                <div className="reasonContent">
                  <h3>{ t('cleaned_the_way_you_want') }</h3>
                  <p>
                    { t('cleaned_the_way_you_want_desc') }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManyReason;
