import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import jQuery from "jquery";
// import { getHomeMainService} from "../actions/homeAction";


const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  // const getAllServicesState = useSelector((state) => state.footerDataReducer);
  // const { services, banner } = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/home/services";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  // const allStates = useSelector((state) => state);
  const { footerServices } = getAllServicesState;
  // useEffect(() => {
  //   dispatch(getFooterDataAction(listUrl));
  // }, [listUrl]);
  // useEffect(() => {
  //   const countryID = localStorage.getItem("userCountryId");
  //   let listMainUrl =
  //     baseUrl +
  //     `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
  //   if (countryID) {
  //     dispatch(getHomeMainService(listMainUrl));
  //   }
  // }, []);
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  const bookService = (serviceId, ui_type, service) => {
    // debugger
    if (currentUser) {
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        navigate("/frequency/checkout/details", {
          state: {
            serviceId: serviceId,
            isBookNow: true,
            isLogin: true,
            ui_type: ui_type
          }
        });
      } else if (ui_type === "Package") {
        navigate("/");
      } else {
        navigate("/frequency/checkout/details?step=2", {
          state: {
            serviceId: serviceId,
            isBookNow: true,
            isLogin: false,
            ui_type: ui_type
          }
        });
      }
    } else {
      navigate("/frequency/checkout/details?step=2", {
        state: {
          serviceId: serviceId,
          isBookNow: true,
          isLogin: false,
          ui_type: ui_type
        }
      });
    }
  };

  function viewServiceDetails(id) {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    localStorage.setItem("serviceDetailId", id);
    setTimeout(() => {
      navigate("/service-details");
    }, 500);
  }

  function viewStaticContent(id) {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    setTimeout(() => {
      navigate(id);
    }, 500);
  }

  function gotoExternalSite(site) {
    window.open(site, "_blank");
  }

  const footerServicesFirstSix = footerServices?.slice(0, 6)
  function toTitleCase(str) {
    return str
      .split(' ')                      // Split string by spaces into an array of words
      .map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )                                // Capitalize the first letter of each word
      .join(' ');                      // Join words back into a single string
  }
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="d-flex justify-content-between flex-wrap">
            {/* <div className="footerLogo">
            </div> */}
            <div className="footerLinks">
              <ul>
                <li>
                  <Link to={"/happ"} onClick={() => window.scrollTo(0, 0)}>
                    The RightHand Happiness Guarantee
                  </Link>
                </li>
                <li>
                  <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
                    {t("about")}
                  </Link>
                </li>
                <li>
                  <Link to={"/press"} onClick={() => window.scrollTo(0, 0)}>
                    {t("Press")}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://righthands.applytojob.com/apply"
                    target="_blank"
                  >
                    {t("career")}
                  </a>
                </li>
                <li>
                  <Link
                    to={"/blogs/list"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("blog")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://righthandssupport.zendesk.com/hc/en-us"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("help")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact-us"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {"Contact Us"}
                  </Link>
                </li>
                <br />
                <br />
                <li>
                  <Link className="d-flex align-items-center px-1"
                    to="https://righthands.applytojob.com/apply">
                    Be a Professional
                  </Link>
                </li>
                <br />
              </ul>
            </div>
            <div className="footerLinks footerLocation">
              <p className="footerLocation">{t("Locations")}</p>
              <ul>
                {country_list?.map((country, i) => {
                  return (
                    <li className="footerLocation">
                      {country.name}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="footerLinks footerserviceLinks ">
              <p className="footerLocation">{t("popular_services")}</p>
              <ul>
                {footerServicesFirstSix?.length > 0
                  ? footerServicesFirstSix.map((service, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#ffffff",
                            // marginRight: 15
                          }}
                          onClick={() =>
                            bookService(
                              service._id,
                              service?.ui_type,
                              service
                            )
                          }
                        >
                          {currentLanguage === "en"
                            ? toTitleCase(service?.name)
                            : service?.name_arabic}
                        </span>
                      </li>
                    );
                  })
                  : ""}
              </ul>
            </div>
            {/* <div
                className="helpQuote"
                data-bs-toggle="modal"
                data-bs-target="#chatModal"
              >
                 
                   <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote2.png.webp" />
               
              </div> */}
          </div>
          <li style={{ listStyle: 'none' }}>
            <div className=" burttonImage mt-5" style={{ width: '30%' }}>
              <a href={"https://apps.apple.com/ae/app/right-hands/id1641289996?itscg=30200&itsct=apps_box_badge&mttnsubad=1641289996"}>
                <img style={{ width: '40%' }}
                  alt="Download on the App Store" title="Download on the App Store"
                  src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1703030400" />
              </a>
              <a href={'https://play.google.com/store/apps/details?id=com.righthands'}>
                <img style={{ width: '40%' }}
                  alt="Get it on Google Play" title="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </a>
            </div>
          </li>
          <li style={{ listStyle: 'none', marginTop: '5%' }}>
            <div className="socialIcon">
              <div className="d-flex justify-content-between">
                <Link
                  style={{ marginRight: '5%' }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://www.facebook.com/RightHandService"
                    )
                  }
                >
                  <img src="/images/facebook.png" alt="Facebook icon" title="Facebook" width="40" height="40"></img>
                </Link>
                <Link
                  style={{ marginRight: '5%' }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://twitter.com/righthandsqa")
                  }
                >
                  <img src="/images/x.png" alt="X icon" title="X" width="40" height="40"></img>
                </Link>
                <Link
                  style={{ marginRight: '5%' }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://instagram.com/right.hands")
                  }
                >
                  <img src="/images/insta.png" alt="Instagram icon" title="Instagram" width="40" height="40"></img>
                </Link>
                <Link
                  style={{ marginRight: '5%' }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://in.pinterest.com/righthandsqa/_saved"
                    )
                  }
                >
                  <img src="/images/pintrest.png" alt="Pintrest icon" title="Pintrest" width="40" height="40"></img>
                </Link>
                <Link
                  style={{ marginRight: '5%' }}
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "http://www.linkedin.com/in/right-hands-qa"
                    )
                  }
                >
                  <img src="/images/linkedin.png" alt="LinkedIn icon" title="LinkedIn" width="40" height="40"></img>
                </Link>
              </div>
            </div>
          </li>
          <br />
          <hr style={{ border: 'none', borderTop: '2px solid white' }} />
          <div className="footerLinks footerserviceLinks ">
            <br />
            <h1 className="footerLocation" style={{ fontSize: '150%' }}>{t("Other RightHand Services")}</h1>
            <div className="d-flex justify-content-between">
              <ul>
                {footerServices?.slice(6, 9)?.length > 0
                  ? footerServices?.slice(6, 9).map((service, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#ffffff",
                            // marginRight: 15
                          }}
                          onClick={() =>
                            bookService(
                              service._id,
                              service?.ui_type,
                              service
                            )
                          }
                        >
                          {currentLanguage === "en"
                            ? toTitleCase(service?.name)
                            : service?.name_arabic}
                        </span>
                      </li>
                    );
                  })
                  : ""}
              </ul>
              <ul>
                {footerServices?.slice(9, 12)?.length > 0
                  ? footerServices?.slice(9, 12).map((service, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#ffffff",
                            // marginRight: 15
                          }}
                          onClick={() =>
                            bookService(
                              service._id,
                              service?.ui_type,
                              service
                            )
                          }
                        >
                          {currentLanguage === "en"
                            ? toTitleCase(service?.name)
                            : service?.name_arabic}
                        </span>
                      </li>
                    );
                  })
                  : ""}
              </ul>
              <ul>
                {footerServices?.slice(12, 14)?.length > 0
                  ? footerServices?.slice(12, 14).map((service, index) => {
                    return (
                      <li key={index}>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#ffffff",
                            // marginRight: 15
                          }}
                          onClick={() =>
                            bookService(
                              service._id,
                              service?.ui_type,
                              service
                            )
                          }
                        >
                          {currentLanguage === "en"
                            ? toTitleCase(service?.name)
                            : service?.name_arabic}
                        </span>
                      </li>
                    );
                  })
                  : ""}
                {
                  // <li className="footerLocation" onClick={() =>
                  //   navigate('/services')
                  // }>See all services</li>
                  <li>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#ffffff",
                        // marginRight: 15
                      }}
                      onClick={() => navigate('/services')
                      }
                    >
                      See all services
                    </span>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>

      </div>
      <Modal />
    </>
  );
};
export default Footer;
