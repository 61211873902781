import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { getFrequencyListAction } from "../actions/frequencyAction";
import axios from "axios";
import { authHeader } from "../helpers/helpers";
import MapSearch from "./MapSearch";
import { toast } from "react-toastify";

const NewServices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [serviceCategory, setServiceCategory] = useState(null);
  const [isCategoryFetched, setIsCategoryFetched] = useState(false);
  const [hoursSelected, setHoursSelected] = useState("4");
  const [prosSelected, setProsSelected] = useState("1");
  const [timeSlotSelected, setTimeSlotSelected] = useState({});
  const [availableSlot, setAvailableSlot] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [checkoutData, setCheckoutData] = useState({});
  const [showAnswer, setShowAnswer] = useState(-1);
  const [pkgSvcCountMap, setPkgSvcCountMap] = useState(new Map());
  const getAllFrequencyState = useSelector((state) => state.frequencyReducer);
  const { loading, frequencies } = getAllFrequencyState;
  const [frequency, setFrequency] = useState("Frequency");
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let serviceByIDUrl = `${baseUrl}/service/view/${id}`;
  const today = new Date().toISOString().split("T")[0];

  const currentUser = JSON.parse(localStorage.getItem("currentUser")) || false;
  const updateShowAnswer = (i) => {
    setShowAnswer(i);
  };

  // Fetching frequency data on component mount
  useEffect(() => {
    const frequencyUrl = `${baseUrl}/get/frequency?country_id=${localStorage.getItem(
      "userCountryId"
    )}&service_type=Home`;
    dispatch(getFrequencyListAction(frequencyUrl, {}));
  }, [baseUrl, dispatch]);

  // Once frequencies are available, set the default frequency
  useEffect(() => {
    if (frequencies?.length > 0) {
      setFrequency(frequencies[0].id);
    }
  }, [frequencies]);

  // Fetching service and category data
  const fetchServiceData = async () => {
    try {
      const serviceResponse = await axios.get(serviceByIDUrl, {
        headers: authHeader(),
      });
      setService(serviceResponse.data.service);

      // Check if the service is a package and category isn't fetched yet
      if (
        serviceResponse.data.service.ui_type === "Package" &&
        !isCategoryFetched
      ) {
        fetchCategoryData(serviceResponse.data.service._id);
      }
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  const fetchCategoryData = async (serviceId) => {
    try {
      const categoryResponse = await axios.post(
        `${baseUrl}/home/service/category`,
        {
          country_id: localStorage.getItem("userCountryId"),
          service_id: serviceId,
        },
        { headers: authHeader() }
      );

      console.log(categoryResponse?.data?.serviceCategory);
      setServiceCategory(categoryResponse?.data?.serviceCategory);
      setIsCategoryFetched(true); // Mark category as fetched
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };
  useEffect(() => {
    fetchServiceData();
  }, [serviceByIDUrl]);
  // Memoizing the handlers to avoid re-creating them on each render
  const handleItemsIncr = useCallback((item) => {
    setPkgSvcCountMap((prevCounts) => {
      const newCounts = new Map(prevCounts);
      const currentCount = newCounts.get(item) || 0;
      newCounts.set(item, currentCount + 1);
      return newCounts;
    });
  }, []);

  const handleItemsDecr = useCallback((item) => {
    setPkgSvcCountMap((prevCounts) => {
      const newCounts = new Map(prevCounts);
      const currentCount = newCounts.get(item) || 0;
      if (currentCount > 0) {
        newCounts.set(item, currentCount - 1);
      }
      return newCounts;
    });
  }, []);

  const handleDateChange = useCallback((event) => {
    setSelectedDate(event.target.value);
    updateCheckoutData("appointment_date", event.target.value);
  }, []);

  const handleFrequencyChange = useCallback((event) => {
    setFrequency(event.target.value);
    updateCheckoutData("frequency", event.target.value);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Fetch time slots based on selected values
  const getTimeSlot = useCallback(async () => {
    let listUrl = `${baseUrl}/appointment/cleaner/time/availability`;
    let data = {
      cleaner_id: "",
      is_auto_assign: true,
      appointment_date: selectedDate,
      hours_count: parseInt(hoursSelected),
      service_id: id,
      is_home_cleaning: false,
      country_id: localStorage.getItem("userCountryId"),
      latitude: localStorage.getItem("mapLatitude"),
      longitude: localStorage.getItem("mapLongitude"),
      frequency_id: frequency !== "Frequency" ? frequency : null,
      professionalCount:
        frequency !== "Frequency" ? parseInt(prosSelected) : undefined,
      customer_id: currentUser ? currentUser._id : "",
    };

    try {
      const response = await axios.post(listUrl, data);
      if (response.data.available_time.length > 0) {
        updateCheckoutData("codCharges", response.data?.codCharges);
        setTimeSlotSelected(response.data.available_time[0]);
      } else {
        setTimeSlotSelected({});
      }
      setAvailableSlot(response.data.available_time);
      setSelectedCleaners(response.data.selectedCleaner);
    } catch (error) {
      setAvailableSlot([]);
      setTimeSlotSelected({});
      // toast.error("Time slot not available. Please select any other date", {
      //   position: "top-right",
      //   duration: 2000,
      // });
    }
  }, [selectedDate, prosSelected, hoursSelected, frequency, id]);

  useEffect(() => {
    getTimeSlot();
  }, [selectedDate, prosSelected, hoursSelected, frequency, getTimeSlot]);

  // Updating checkout data
  const updateCheckoutData = (key, value) => {
    setCheckoutData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleFormSubmit = () => {
    console.log("abc ", { frequency }, { timeSlotSelected }, { hoursSelected }, { prosSelected }, { currentUser })
    if (!currentUser) {
      toast.error("Please login to continue your booking", {
        duration: 2000,
        position: "top-right",
      });
    }
    else if (
      !frequency ||
      frequency === "Frequency" ||
      !timeSlotSelected || // null or undefined
      Object.keys(timeSlotSelected).length === 0 ||
      !hoursSelected ||
      !prosSelected
    ) {
      toast.error("Fill/select all the fields to book the service", {
        duration: 2000,
        position: "top-right",
      });
    } else {
      const currentCheckoutData = {
        frequency_id: frequency,
        hours: hoursSelected,
        no_of_pros: prosSelected,
        appointment_date: selectedDate,
        service_id: id,
        country_id: localStorage.getItem("userCountryId"),
        time_slot: timeSlotSelected.time,
        start_time: timeSlotSelected.start_time,
        end_time: timeSlotSelected.end_time,
        slot_price: timeSlotSelected.totalSlotPrice,
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude"),
        customer_id: currentUser ? currentUser._id : "",
        service_price: service?.new_price * hoursSelected,
        service: service,
        customer: currentUser,
        selected_cleaners: selectedCleaners,
        cod_charges: checkoutData?.codCharges,
      };

      setCheckoutData(currentCheckoutData);

      navigate("/summary", {
        state: {
          checkoutData: currentCheckoutData,
        },
      });
    }
  };

  const handleTimeSlotChange = (event) => {
    const selectedSlot = JSON.parse(event.target.value);
    console.log("abc: ", { selectedSlot })
    setTimeSlotSelected(selectedSlot);
    updateCheckoutData("time_slot", selectedSlot.time);
    updateCheckoutData("start_time", selectedSlot.start_time);
    updateCheckoutData("end_time", selectedSlot.end_time);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Header />
      {service?.ui_type !== "Package" ? (
        <>
          <div style={{ marginBottom: "20%" }}>
            <div
              className="background-image-service-booking"
              style={{
                backgroundImage: `url(${service?.icon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="booking-form background-white-shadow">
                <div className="form-style-service">
                  <br />
                  <p>{isCategoryFetched}</p>
                  <h2 style={{ marginBottom: "30px" }}>{service?.name}</h2>
                  <div style={{ marginBottom: '2%' }}>
                    {[0, 0, 0, 0, 0].map((_, index) => {
                      const isHalfStar = 3.5 - (index) === 0.5;
                      const isFullStar = 3.5 > index;
                      return (
                        <span
                          key={index}
                          className={`fa ${isFullStar ? "fa-star" : isHalfStar ? "fa-star-half-o" : "fa-star-o"
                            }`}
                          style={{ color: isFullStar || isHalfStar ? "orange" : "gray", fontSize: 'large' }}
                          aria-hidden="true"
                        ></span>
                      );
                    })}
                    <span><u style={{ fontSize: '16px', marginLeft: '2%' }}>55,007 Reviews</u></span>
                  </div>
                  <form className="form-flex">
                    <div className="pro-selection">
                      <select
                        className="form-element-border frequency-dropdown"
                        placeholder="Frequency"
                        value={frequency}
                        onChange={handleFrequencyChange}
                      >
                        {frequencies?.map((f, i) => {
                          return <option value={f.id}>{f.package_name}</option>;
                        })}
                      </select>
                      <div className="d-flex service-booking">
                        <select
                          className="select-dropdown-service"
                          value={hoursSelected}
                          onChange={(event) => {
                            setHoursSelected(event.target.value);
                            updateCheckoutData("hours", event.target.value);
                          }}
                        >
                          <option value="2">2 hours</option>
                          <option value="3">3 hours</option>
                          <option value="4">4 hours</option>
                          <option value="5">5 hours</option>
                        </select>
                        <select
                          className="select-dropdown-service"
                          value={prosSelected}
                          onChange={(event) => {
                            setProsSelected(event.target.value);
                            updateCheckoutData("no_of_pros", event.target.value);
                          }}
                        >
                          <option value="1">
                            1{" "}
                            {service?.ui_type === "Frequency"
                              ? "pro"
                              : service?.ui_type === "Wheel"
                                ? "Babysitter"
                                : ""}
                          </option>
                          <option value="2">
                            2{" "}
                            {service?.ui_type === "Frequency"
                              ? "pros"
                              : service?.ui_type === "Wheel"
                                ? "Babysitters"
                                : ""}
                          </option>
                          <option value="3">
                            3{" "}
                            {service?.ui_type === "Frequency"
                              ? "pros"
                              : service?.ui_type === "Wheel"
                                ? "Babysitters"
                                : ""}
                          </option>
                          <option value="4">
                            4{" "}
                            {service?.ui_type === "Frequency"
                              ? "pros"
                              : service?.ui_type === "Wheel"
                                ? "Babysitters"
                                : ""}
                          </option>
                        </select>
                      </div>
                      <div className="d-flex service-booking">
                        <input
                          type="date"
                          value={selectedDate} // Bind to the state for controlled component
                          onChange={handleDateChange} // Update the state when a new date is selected
                          min={today} // Disable past dates
                          defaultValue={today} // Set the default date to today
                          className="no-calendar select-dropdown-service"
                        />

                        <select
                          onChange={handleTimeSlotChange}
                          className="select-dropdown-service"
                          style={{ width: "100%", minWidth: "20vh" }}
                          required
                        >
                          {availableSlot?.map((as, i) => (
                            <option key={i} value={JSON.stringify(as)}>
                              {as.time}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="pro-selection">
                      <div className="location-pro show-wide">
                        <MapSearch short={false} />
                      </div>
                      <div className="location-pro hide-wide">
                        <MapSearch short={true} />
                      </div>
                      <button
                        className="booking-continue-button btn-continue initial-height btn btn-primary"
                        style={{ width: "22%", backgroundColor: "#8CC63F", borderRadius: "0px" }}
                        type="button"
                        onClick={handleFormSubmit}
                      >
                        Book Now
                      </button>
                    </div>
                    <div>
                      <p style={{ marginTop: "40px" }}>
                        Customise your booking as needed!
                      </p>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }} className="service-booking small-service-booking">
          <div
            className="background-white-shadow why-book-service why-book-service-package"
          >
            <div className="why-book-service-main">
              <br />
              <h1>Why Choose Right Hands for Your {service?.name}?</h1>
              <p>
                When it comes to home cleaning, Right Hands is your go-to
                solution. We offer a seamless, hassle-free experience that takes
                the stress out of keeping your space spotless. Whether you need
                a one-time clean, regular service, or something in between, our
                in-house team of trained professionals delivers the highest
                quality results every time. We believe in providing top-notch
                service at fair prices, ensuring your home gets the attention it
                deserves without breaking the bank. Plus, with our easy-to-use
                platform, scheduling recurring cleanings is automatic, so you
                cari sit back, relax, and focus on what really matters.
              </p>
              <ul className="why-book-list tick-list">
                <li>Vetted and screened professionals.</li>
                <li>Backed by the RightHand Happiness Guarantee.</li>
                <li>Friendly 24/7 customer service</li>
                <li>Affordable, upfront pricing</li>
              </ul>
              <button
                className="btn-continue initial-height btn btn-primary"
                onClick={handleScrollToTop}
              >
                Get Started
              </button>
            </div>
          </div>
          <div
            className="background-white-shadow container package-service-right"
          >
            <br />
            <h3>{service?.name}</h3>
            <div
              style={{ width: "95%", textAlign: "center", marginLeft: "5%" }}
            >
              <MapSearch short={true} />
            </div>

            <h3>Tell us more about the job</h3>
            <div>
              {serviceCategory?.map((sc, i) => {
                return (
                  <>
                    <div
                      className="d-flex justify-space-between service-booking"
                      style={{ alignContent: "center", alignItems: "center" }}
                    >
                      <button
                        type="button"
                        onClick={() => handleItemsDecr(sc?._id)}
                        style={{
                          border: "1px solid black",
                          width: "20%",
                          minHeight: "3rem",
                          marginLeft: "2.5%",
                          alignContent: 'center',
                          fontSize: 'x-large',
                          background: 'none',
                          borderTopLeftRadius: '5px',
                          borderBottomLeftRadius: '5px',
                          color: 'grey'
                        }}
                      >
                        -
                      </button>
                      <div
                        style={{
                          border: "1px solid black",
                          width: "55%",
                          minHeight: "3rem",
                          alignContent: 'center'
                        }}
                      >
                        {" "}
                        {pkgSvcCountMap.get(sc?._id) || 0} {sc.name}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleItemsIncr(sc?._id)}
                        style={{
                          border: "1px solid black",
                          width: "20%",
                          minHeight: "3rem",
                          alignContent: 'center',
                          fontSize: 'x-large',
                          background: 'none',
                          backgroundColor: 'none',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          color: 'grey'
                        }}
                      >
                        +
                      </button>
                    </div>
                    <br />
                  </>
                );
              })}
            </div>
            <br />
            <br />
            <div>
              <h4>When and for how long would you like a Pro to visit?</h4>
              <select
                value={hoursSelected}
                onChange={(event) => {
                  setHoursSelected(event.target.value);
                  updateCheckoutData("hours", event.target.value);
                }}
                style={{ width: "85%", height: "3rem", fontSize: 'medium' }}
              >
                <option value="2">2 hours</option>
                <option value="3">3 hours</option>
                <option value="4">4 hours</option>
                <option value="5">5 hours</option>
              </select>
            </div>
            <br />
            <div>
              <br />
              <input
                type="date"
                value={selectedDate} // Bind to the state for controlled component
                onChange={handleDateChange} // Update the state when a new date is selected
                min={today} // Disable past dates
                defaultValue={today} // Set the default date to today
                className="no-calendar"
                style={{ width: "85%" }}
              />
              <br />
              <br />
              <select
                style={{ width: "85%", minWidth: "20vh", height: "3rem" }}
                onChange={(event) => {
                  let selectedSlot = JSON.parse(event.target.value);
                  setTimeSlotSelected(selectedSlot);
                  updateCheckoutData("time_slot", selectedSlot.time);
                  updateCheckoutData("start_time", selectedSlot.start_time);
                  updateCheckoutData("end_time", selectedSlot.end_time);
                }}
              >
                {availableSlot?.map((as, i) => {
                  return <option value={JSON.stringify(as)}>{as.time}</option>;
                })}
              </select>
            </div>
            <br />
            <br />
            <button
              className="booking-continue-button btn-continue initial-height btn btn-primary"
              style={{ width: "75%" }}
              type="button"
              onClick={handleFormSubmit}
              disabled={true}
            >
              Book Now
            </button>
          </div>
        </div>
      )}

      <div className="container">
        <div className={`how-works ${service?.ui_type === "Package" ? "how-works-small-package" : "how-works-small"}`}>
          <div className="how-works-header">
            <hr />
            <h1>How RightHands Works</h1>
            <hr />
          </div>

          <div className="how-works-items">
            <div className="how-works-subitems">
              <img
                src="/images/customise-book.png"
                alt="how=righthand-works"
                title="Customise and Book as per your needs"></img>
              <h4>Customise & book</h4>
              <p>
                Just specify a few details, pick the date and time, we'll send
                the right professional
              </p>
            </div>
            <div className="how-works-subitems">
              <img
                src="/images/easily-manage.png"
                alt="Eaily Manage"
                title="Easily Manager everything using RightHands"></img>
              <h4>Easily manage everything</h4>
              <p>
                Choose a weekly, biweekly, or monthly cleaning plan. We schedule
                your recurring bookings to make things easy - but don’t worry,
                you can always reschedule if things change.
              </p>
            </div>
            <div className="how-works-subitems">
              <img
                src="/images/sit-relax.png"
                alt="relax"
                title="Sit and Relax, let Righthands get the job done!"></img>
              <h4>Sit Back and Relax</h4>
              <p>
                Rest, work, play, live... while the professional takes care of
                everything.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="background-white-shadow">
            <div className="how-book">
                <div className="how-book-header">
                    <h2>Effortless Home Cleaning, Tailored for You</h2>
                    <p>Booking a professional home cleaning has never been easier. Just enter your location, select the number of hours and professionals. We'll instantly connect you with trusted ancl experienced cleaners who will leave your home sparkling, so you can focus on what truly matters. Right Hands where your home is in good hands.
                    </p>
                </div>
                <div className="how-book-items">
                <div className="items-left">
                <div className="how-book-sub-items">
                    <div className="sub-items-header">
                        <img className="sub-items-img" src="/images/experienced.png" alt="icon" />
                        <h3>Experienced</h3>
                    </div>
                    <p>Right Hands Home Services has been delivering exceptional home cleaning services for years, with a dedicated team of our own pro fessional staff. Whether it's your apartment, villa, or home, our trained professionals ensure every corner gets the attention it deserves</p>
                </div>
                <div className="how-book-sub-items">
                    <div className="sub-items-header">
                        <img className="sub-items-img" src="/images/convenient.png" alt="icon" />
                        <h3>Convenient</h3>
                    </div>
                    <p>
                        Busy schedules and family commitments can make it tough to keep up with cleaning Whether you need help with a one-time clearing or regular service, Right Hands is here to make your life sasier. Just book through our website or app, and our team will take care of the rest
                    </p>
                </div>
                </div>
                <div>
                <div className="how-book-sub-items">
                    <div className="sub-items-header">
                        <img className="sub-items-img" src="/images/reliable.png" alt="icon" />
                        <h3>Reliable</h3>
                    </div>
                    <p>
                        When you book with Right Hands, you're guaran beed that our professional cleaners will arrive on time, ready to bring your home back to its best condition. From bedrooms and bathrooms to kitchens and living areas, our team will make sure your space is spotless and refreshed.
                    </p>
                </div>
                <div className="how-book-sub-items">
                    <div className="sub-items-header">
                        <img className="sub-items-img" src="/images/flexible.png" alt="icon" />
                        <h3>Flexible</h3>
                    </div>
                    <p>
                        With Right Hands, scheduling your cleanings is tasy and flexible. Choose the time and date that fits your needs, and if you need to adjust, simply reschedule through our platform You can also customize your our booking with additional services blurinaning, deep cleaning, and more
                    </p>
                </div>
                </div>
                </div>
            </div>
            </div> */}
      </div>
      {service?.ui_type !== "Package" ? (
        <div className="background-white-shadow why-book-service">
        <div className="why-book-service-main">
          <br />
          <hr />
          <h1>Why Choose Right Hands for Your {service?.name}?</h1>
          <p>
            When it comes to home cleaning, Right Hands is your go-to solution.
            We offer a seamless, hassle-free experience that takes the stress
            out of keeping your space spotless. Whether you need a one-time
            clean, regular service, or something in between, our in-house team
            of trained professionals delivers the highest quality results every
            time. We believe in providing top-notch service at fair prices,
            ensuring your home gets the attention it deserves without breaking
            the bank. Plus, with our easy-to-use platform, scheduling recurring
            cleanings is automatic, so you cari sit back, relax, and focus on
            what really matters.
          </p>
          <ul className="why-book-list tick-list">
            <li>Vetted and screened professionals.</li>
            <li>Backed by the RightHand Happiness Guarantee.</li>
            <li>Friendly 24/7 customer service</li>
            <li>Affordable, upfront pricing</li>
          </ul>
          <button
            className="btn-continue initial-height btn btn-primary"
            onClick={handleScrollToTop}
          >
            Get Started
          </button>
        </div>
      </div>
      ) : ""}
      
      {/* <div className="background-grey">
                <div className="whats-included">
                    <div className="whats-include-header">
                    <h2>What's Included in a House Cleaning?</h2>
                    <p>Here is what you can generally expect from a house cleaning booked through Handy. Download the app to share your specific cleaning details and instructions with your pro!</p>
                </div>
                <div className="whats-included-items">
                    <div className="whats-included-items-background">
                    </div>
                    <div className="whats-include-items-text">
                        <h3>Bedroom, Living Room & Common Areas</h3>
                        <ul className="items-list">
                            <li>Dust all accessible surfaces</li>
                            <li>Wipe down all mirrors and glass fixtures</li>
                            <li>Clean all floor surfaces</li>
                            <li>Take out garbage and recycling</li>
                        </ul>
                    </div>
                </div>
                <div className="whats-included-items">
                <div className="whats-include-items-text">
                        <h3>Bathroom Cleaning</h3>
                        <ul className="items-list">
                            <li>Wash and sanitize the toilet, shower, tub and sink</li>
                            <li>Dust all accessible surfaces</li>
                            <li>Wipe down all mirrors and glass fixtures</li>
                            <li>Clean all floor surfaces</li>
                            <li>Take out garbage and recycling</li>
                        </ul>
                    </div>
                    <div className="whats-included-items-background">
                    </div>
                    
                </div>
                <div className="whats-included-items">
                    <div className="whats-included-items-background">
                    </div>
                    <div className="whats-include-items-text">
                        <h3>Bedroom, Living Room & Common Areas</h3>
                        <ul className="items-list">
                            <li>Dust all accessible surfaces</li>
                            <li>Wipe down all mirrors and glass fixtures</li>
                            <li>Clean all floor surfaces</li>
                            <li>Take out garbage and recycling</li>
                        </ul>
                    </div>
                </div>
                <div className="whats-included-items">
                <div className="whats-include-items-text">
                        <h3>Bedroom, Living Room & Common Areas</h3>
                        <ul className="items-list">
                            <li>Dust all accessible surfaces</li>
                            <li>Wipe down all mirrors and glass fixtures</li>
                            <li>Clean all floor surfaces</li>
                            <li>Take out garbage and recycling</li>
                        </ul>
                    </div>
                    <div className="whats-included-items-background">
                    </div>
                    
                </div>
                </div>
            </div> */}
      <div className=" faqs">
        
        {service?.faqs.length > 0 ? <h2 className="faqs-heading">Frequently Asked Questions</h2> : ""}
        {service?.faqs?.map((qa, i) => {
          return (
            <div>
              <div
                className="d-flex justify-content-between ques-click"
                onClick={() => {
                  showAnswer === i ? updateShowAnswer(-1) : updateShowAnswer(i);
                }}
              >
                <h3 className="ques">{qa.ques}</h3>
                <p
                  className="ques-icon"
                  onClick={() => {
                    showAnswer === i
                      ? updateShowAnswer(-1)
                      : updateShowAnswer(i);
                  }}
                >
                  {showAnswer === i ? "-" : "+"}
                </p>
              </div>
              {i === showAnswer && <p className="ans">{qa.ans}</p>}
              <hr />
            </div>
          );
        })}
      </div>
      <div className="background-grey">
        <div className="container locations">
          <br />
          <div className="locations-header">
            <h2>Where does RightHands Operate?</h2>
            <p>
              RightHands operates in most of the countries of the middle east
              asia
            </p>
          </div>
          <div className="locations-item background-white-shadow locations-item-weight">
            <ul className="items-list">
              <li>Qatar</li>
              <li>India</li>
              <li>UAE</li>

              <li>Kuwait</li>
              <li>Oman</li>
              <li>Bahrain</li>
            </ul>
          </div>
          <br />
        </div>
      </div>
      <div className="container why-righthands-best">
        <h2>Here's Why RightHands is the Best Way to Book A House Cleaning</h2>
        <h4>Instant online booking with 7am-11pm availability</h4>
        <p>
          Book online instantly, and schedule your first cleaning for as early
          as tomorrow. Get your home cleaned anytime from 7am to 11pm, 7 days a
          week (subject to local pro availability).
        </p>

        <h4>Friendly, vetted professionals</h4>
        <p>
          All professionals on the Handy platform are screened, vetted
          professionals, and are rated by customers like you to ensure quality.
        </p>

        <h4>Cleaned the way you want</h4>
        <p>
          Professionals bring supplies and work from your own custom
          instructions. You can work with the same cleaner every time. Handy
          strives to match you with the right professional for you and your
          home. We also provide you with a team of professionals to provide
          backup in case of scheduling conflicts.
        </p>

        <button
          className="btn-continue initial-height btn btn-primary"
          onClick={handleScrollToTop}
        >
          Get Started
        </button>
      </div>
      <Footer />
    </>
  );
};

export default React.memo(NewServices);
