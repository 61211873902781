import {useNavigate} from "react-router-dom";
import React, {useEffect, useState, useCallback} from "react";
import OwlCarousel from "react-owl-carousel";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getHomeDataAction, getHomeMainService} from "../actions/homeAction";
import Application from "./scrollSlider";

function ServiceBanner() {
  const {t} = useTranslation();
  let navigate = useNavigate();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
    const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services} = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/home/services";
  const [serviceType, setServiceType] = useState([]);
  const [mainService, setMainService] = useState(true);
  const [showServiceType, setShowServiceType] = useState(false);
  const [showService, setShowServie] = useState([]);
  const [serviceDisplay, setServiceDisplay] = useState(false);
  // useEffect(() => {
  //   dispatch(getHomeDataAction(listUrl));
  // }, [listUrl]);
  const countryId = localStorage.getItem("userCountryId");
  // let listMainUrl = baseUrl+`/home/dashboard?country_id=${localStorage.getItem('userCountryId')}`;

  useEffect(() => {
    const countryId = localStorage.getItem("userCountryId");
    let listMainUrl = baseUrl + `/home/dashboard?country_id=${countryId}`;

    if (countryId) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, [countryId]);

  const viewMainServiceDetails = (service) => {
    setServiceType(service.serviceTypes);
    setShowServiceType(true);
    setMainService(false);
  };

  async function viewServiceDetails(id, ui_type, serviceName) {
    if (ui_type === "Frequency" || ui_type === "Wheel") {
      if (localStorage.getItem("frequencyCheckoutData") !== null) {
        localStorage.removeItem("frequencyCheckoutData");
        localStorage.removeItem("frequencyAppointmentData");
        localStorage.removeItem("bookAppointmentId");
      }
      localStorage.setItem("frequencyTypeServiceDetailId", id);
      localStorage.setItem("serviceName", serviceName);
    } else {
      if (
        localStorage.getItem("serviceDetailId") &&
        localStorage.getItem("serviceDetailId") !== id
      ) {
        localStorage.removeItem("bookAppointmentCartData");
        localStorage.removeItem("bookAppointmentDetails");
      }
      localStorage.setItem("serviceDetailId", id);
    }
    // setTimeout(() => {
      navigate(`/frequency/checkout/details?step=${currentUser ? 1 : 2}`, {
        state: {
          serviceId: id,
          isBookNow: false,
          isLogin: currentUser ? true : false,
          ui_type: ui_type
        }
      });
      // navigate(
      //   ui_type === "Frequency" || ui_type === "Wheel"
      //     ? "/frequency/checkout/details"
      //     : "/service/checkout/details?step=1"
      // );
    // }, 500);
  }

  const options = {
    margin: 15,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    rtl: true,
    ltr: false,
    lazyLoad: false,
    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      400: {
        items: 1
      },
      600: {
        items: 3
      },

      700: {
        items: 3
      },

      1000: {
        items: 3
      },
      1200: {
        items: 6
      }
    }
  };

  const handlerChangeService = useCallback((e) => {
    e.preventDefault();

    let serviceName = e.target.value.toUpperCase();
    const ser = services?.filter((f) => f.name.includes(serviceName));
    if (ser) {
      setShowServie(ser);
      setServiceDisplay(true);
    }
  }, []);

  return (
    <>
      <div className="serviceBanner w-full pt-7 d-none">
        <div className="container">
          <div className="heading">
            <h5>{t("everything_you_need")}</h5>

            <h2>{t("explore_our_services")}</h2>
            <h2>You Name it. We Do it.</h2>
            <p>Pick any of the services below</p>
            <input type="text" onChange={(e) => handlerChangeService(e)} />
          </div>
          {services?.length > 0 ? (
            <OwlCarousel
              className="owl-theme"
              items={6}
              loop={false}
              touchDrag={false}
              mouseDrag={false}
              margin={25}
              nav
              lazyLoad={false}
              {...options}
            >
              {serviceDisplay === false ? (
                <>
                  {services?.length > 0 && mainService ? (
                    services.map((service, index) => {
                      return (
                        <div className="item" key={index}>
                          <div
                            className="serviceSlider"
                            onClick={() => viewMainServiceDetails(service)}
                          >
                            <span>
                              <img
                                src={
                                  service?.icon
                                    ? service?.icon
                                    : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                                }
                                alt="window"
                              />
                            </span>
                            <h3>
                              {currentLanguage === "en"
                                ? service.name
                                : service.name_arabic}
                            </h3>
                            <p>
                              {currentLanguage === "en"
                                ? service.description
                                : service.description_arabic}
                            </p>
                            <button className="btn btn-primary">
                              {t("book_now")}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="">{t("no_services_found")}</p>
                  )}
                </>
              ) : (
                <>
                  {showService?.length > 0 && mainService ? (
                    showService.map((service, index) => {
                      return (
                        <div className="item" key={index}>
                          <div
                            className="serviceSlider"
                            onClick={() => viewMainServiceDetails(service)}
                          >
                            <span>
                              <img
                                src={
                                  service?.icon
                                    ? service?.icon
                                    : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                                }
                                alt="window"
                              />
                            </span>
                            <h3>
                              {currentLanguage === "en"
                                ? service.name
                                : service.name_arabic}
                            </h3>
                            <p>
                              {currentLanguage === "en"
                                ? service.description
                                : service.description_arabic}
                            </p>
                            <button className="btn btn-primary">
                              {t("book_now")}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="">{t("no_services_found")}</p>
                  )}
                </>
              )}
            </OwlCarousel>
          ) : (
            ""
          )}

          {serviceType?.length > 0 && showServiceType ? (
            <OwlCarousel
              className="owl-theme"
              items={6}
              loop={false}
              touchDrag={false}
              mouseDrag={false}
              margin={25}
              nav
              lazyLoad={false}
              {...options}
            >
              {serviceType?.length > 0 && showServiceType ? (
                serviceType.map((service, index) => {
                  return (
                    <div className="item" key={index}>
                      <div
                        className="serviceSlider"
                        onClick={() =>
                          viewServiceDetails(
                            service._id,
                            service.ui_type,
                            service.name
                          )
                        }
                      >
                        <span>
                          <img
                            src={
                              service?.icon
                                ? service?.icon
                                : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                            }
                            alt="window"
                          />
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="">{t("no_services_found")}</p>
              )}
            </OwlCarousel>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default ServiceBanner;
