import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { authHeader } from "../../helpers/helpers";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import ServiceList from "../customer/services/ServiceList";
import ScrollableCategories from "./ScrollableCateogries";
import { useDispatch, useSelector } from "react-redux";
import { getHomeMainService } from "../../actions/homeAction";
import Footer from "../../components/Footer";
import _debounce from "lodash/debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const AllServices = () => {
  const { t } = useTranslation();
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services } = getAllServicesState;
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState(false);
  const [searchServiceName, setSearchServiceName] = useState();
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  const currentLanguage = localStorage.getItem("currentLanguage")
  ? localStorage.getItem("currentLanguage")
  : "en";

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    // Fetch categories and their respective services
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);

  function handleDebounceFn(inputValue, toggleState) {
    if (inputValue === "") {
      emptyService();
    } else {
      let serviceName = inputValue.toUpperCase();
      let url = `${baseUrl}/home/dashboard?country_id=${localStorage.getItem(
        "userCountryId"
      )}&name=${serviceName}`;
      dispatch(getHomeMainService(url));
      setServiceName(true);
    }
  }

  const handlerChangeService = useCallback((e) => {
    e.preventDefault();
    setSearchServiceName(e.target.value);
    debounceFn(e.target.value);
  }, []);

  const emptyService = () => {
    let url = `${baseUrl}/home/dashboard?country_id=${localStorage.getItem(
      "userCountryId"
    )}`;
    dispatch(getHomeMainService(url));
    setServiceName(false);
  };

  return (
    <>
      <Header />
      {/* <div>
        <img
          src="/images/banner-image.jpg"
          style={{ width: "90%", marginBottom: "3%" }}
        />
      </div> */}
      <div
      style={{
        backgroundImage: "url('images/banner-image.jpg')", // Replace with your background image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "40px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <h2
          style={{
            margin: "0 0 20px 0",
            fontWeight: "bold",
            fontSize: "clamp(1.2rem, 2.5vw, 1.5rem)", // Dynamically adjusts font size
          }}
        >
          Choose a service to get started.
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            flexWrap: "wrap", // Allows items to wrap on smaller screens
          }}
        >
          <input
            type="text"
            placeholder='Search for a service (e.g. "cleaning")'
            style={{
              flex: "1",
              padding: "12px 16px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              minWidth: "200px", // Ensures input doesn't shrink too much
              width: "100%",
            }}
            onChange={handlerChangeService}
          />
          <button
            style={{
              padding: "12px 16px",
              backgroundColor: "#9e6dc8",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
              minWidth: "100px", // Ensures button doesn't shrink too much
            }}
            type="button"
            onClick={handlerChangeService}
          >
            🔍
            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" style={{ marginRight: "8px", fontSize: "14px" }}/>
          </button>
        </div>
      </div>
    </div>
      <ScrollableCategories categories={services} />
      <Footer />
    </>
  );
};

export default AllServices;
