import React,{useEffect, useState} from "react";
import Loader from "./Loader";
import axios from "axios";
import { authHeader } from "../helpers/helpers";

const Deeplink = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const url = window.location.pathname
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const servicesUrl =  baseUrl + `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;

    useEffect(async () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Attempt to open the Android app
            const appUrl = 'https://play.google.com/store/apps/details?id=com.righthands&pli=1'; // Replace with your custom URL scheme
            const appStoreUrl = 'https://play.google.com/store/apps/details?id=com.righthands&pli=1';

            // Try to open the app, fallback to the store
            window.location.href = appUrl;

            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 2000); // Delay before redirecting to the app store
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Attempt to open the iOS app
            const appUrl = 'https://apps.apple.com/in/app/right-hands/id1641289996'; // Replace with your custom URL scheme
            const appStoreUrl = 'https://apps.apple.com/in/app/right-hands/id1641289996';

            // Try to open the app, fallback to the store
            window.location.href = appUrl;

            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 2000); // Delay before redirecting to the app store
        } else {
            // Handle unknown device or desktop
            let voucher = params.get('voucher');
            if (url.includes("/referral")) {
                window.location.href = process.env.REACT_APP_INVITE_URL+'/my-account/referral'
            } else if(voucher && voucher !== ''){
                localStorage.setItem('couponCode', voucher);
                try {
                    const response = await axios.get(servicesUrl,{
                        headers: authHeader(),
                    });
                    if (response.status != 200) {
                      throw new Error('Network response was not ok');
                    }
                    const data = response.data.services;

                    // Find the ID of the service type with name "Home Cleaning"
                    const homeCleaningService = data.flatMap(service =>
                      service.serviceTypes
                    ).find(type => type.name === 'Home Cleaning');

                    if (homeCleaningService) {
                    //   navigate(`/service?id=${homeCleaningService._id}`);
                      window.location.href = process.env.REACT_APP_INVITE_URL+'/frequency/checkout/details?step=1&hours_count=4&professionals_count=1&is_cleaning_materials=true&frequency_id=6253b87b59af23d16f5a584a&voucher='+voucher+'&service_id='+homeCleaningService._id;
                    } else {
                        window.location.href = process.env.REACT_APP_INVITE_URL
                    }
                  } catch (error) {
                    console.error('Failed to fetch services:', error);
                    window.location.href = process.env.REACT_APP_INVITE_URL
                  }
            }else{
                window.location.href = process.env.REACT_APP_INVITE_URL;
            }
        }
        // setTimeout(() => {
        // }, 1000);
      }, []);
    return (
        <div>
            <Loader/>
        </div>
    )
};

export default Deeplink;
